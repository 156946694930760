<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app right>
      <template v-slot:prepend>
        <v-list-item two-line @click="$router.push({ name: 'profile' })">
          <v-list-item-avatar>
            <v-avatar color="primary">
              <img
                v-if="user.avatar"
                :src="user.avatar | full_avatar"
                alt="Cropped Image"
                @error="user.avatar = null"
              />
              <span v-else class="headline">
                <v-icon>mdi-account</v-icon>
              </span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              >{{ user.fname }} {{ user.lname }}</v-list-item-title
            >
            <v-list-item-subtitle>
              {{ roles[0] }}
              {{ user.office ? ` | ${user.office.name}` : null }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider />
      <sidebar :key="sidebarKey" />
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        <img src="@/assets/logo.png" alt="s" />
      </v-toolbar-title>
      <v-spacer />
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$store.commit('openReminder', true)">
            <v-list-item-icon>
              <v-icon>mdi-alarm-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>افزودن یادآوری</v-list-item-title>
          </v-list-item>
          <v-list-item @click="changeTheme">
            <v-list-item-icon>
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-icon>
            <v-list-item-title>عوض کردن تم سیستم</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <transition>
        <router-view v-if="sidebarKey" :key="sidebarKey" />
      </transition>
    </v-main>
    <back-to-top bottom="50px" right="50px">
      <v-dialog
        v-model="$store.state.reminderDialog"
        :key="$store.state.reminderDialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span>ایجاد یادآوری</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    rows="2"
                    label="پیام"
                    v-model="form.message"
                    placeholder="متن یادآوری را بنویسید"
                    required
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="form.target_date"
                    label="تاریخ یادآوری"
                    id="datetime-picker"
                  />
                  <datetime-picker
                    :show="datePopup"
                    type="date"
                    :min="today"
                    format="jYYYY/jMM/jDD"
                    v-model="form.target_date"
                    element="datetime-picker"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              text
              @click="$store.commit('openReminder', false)"
              >بستن</v-btn
            >
            <v-btn color="success darken-1" @click="setReminder">ذخیره</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <button type="button" class="btn btn-info btn-to-top">
        <v-icon>mdi-up</v-icon>
      </button>
    </back-to-top>
  </v-app>
</template>

<script>
import BackToTop from "vue-backtotop";
import moment from "moment-jalaali";
import DatetimePicker from "vue-persian-datetime-picker";
import Sidebar from "./Sidebar";
import store from "../../../store";
import GeneralApi from "../../../networks/GeneralApi";

export default {
  components: {
    BackToTop,
    Sidebar,
    "datetime-picker": DatetimePicker
  },
  data() {
    return {
      drawer: null,
      routeTitle: this.$route.meta.pageTitle,
      today: moment().format("jYYYY/jMM/jDD"),
      appName: process.env.VUE_APP_NAME || "GO2TR BMS",
      apiUrl: process.env.VUE_APP_URL,
      roles: [],
      sidebarKey: 0,
      user: {},
      form: {},
      dialog: false,
      datePopup: false
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      this.permissions();
    }
    document.title = this.$route.meta.title || "GO2TR BMS";
    this.user = JSON.parse(localStorage.getItem("user"));
    const userChoiceTheme = localStorage.getItem("theme");
    const userChoiceIsDark = userChoiceTheme === "dark";
    this.$vuetify.theme.dark = userChoiceTheme
      ? userChoiceIsDark
      : window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches;
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.title;
      document.title = this.$route.meta.title || "GO2TR BMS";
    }
  },
  methods: {
    async permissions() {
      try {
        let { data } = await new GeneralApi().getAdmin();
        data = data.data;
        localStorage.setItem("roles", data.roles);
        this.roles = data.roles_fa;
        this.user = data.admin;
        await this.$laravel.setPermissions(data.permissions);
        window.setTimeout(() => {
          this.sidebarKey = Math.random();
        }, 1);
      } catch (error) {
        store.commit("showError", {
          message: error.response.data.message || "خطا در دریافت دسترسی ها",
          color: "danger"
        });
      }
    },
    async setReminder() {
      try {
        const res = await new GeneralApi().setReminder(this.form);
        this.form = {};
        this.dialog = false;
        store.commit("openReminder", false);
        store.commit("showError", {
          message: res.data.message,
          color: "success"
        });
      } catch (error) {
        store.commit("showError", {
          message: error.response.data.message || "خطا در دریافت دسترسی ها",
          color: "danger"
        });
      }
    },
    changeTheme() {
      const currentTheme = localStorage.getItem("theme");
      localStorage.setItem("theme", currentTheme === "dark" ? "light" : "dark");
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
};
</script>
<style>
.sidebar {
  height: 100vh;
  width: 256px;
  position: absolute;
}
</style>
