
















































































import { Component, Vue } from "vue-property-decorator";
import sidebarItems from "@/views/layouts/main/sidebarItems";
import main from "@/main";

@Component
export default class Sidebar extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private items: Array<Record<string, any>> = sidebarItems;
  private selected = 0;
  private dialog = false;
  private roles = localStorage.getItem("roles") || [];

  public logout(): void {
    localStorage.removeItem("token");
    this.$router.replace({ name: "login" });
  }
}
