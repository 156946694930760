export default [
  {
    url: "/dashboard",
    name: "داشبورد",
    permissions: ["full dashboard", "own dashboard"],
    slug: "dashboard",
    icon: "mdi-home"
  },
  {
    url: "/quick",
    name: "ثبت سریع مشتری",
    permissions: ["add quick user"],
    slug: "quick",
    icon: "mdi-account-plus"
  },
  {
    url: "/financial",
    name: "مالی",
    permissions: ["own finance"],
    slug: "finance",
    icon: "mdi-finance"
  },
  {
    url: "/calls",
    name: "تماس‌ها",
    permissions: ["full list calls", "own list calls", "office list calls"],
    slug: "calls",
    icon: "mdi-phone"
  },
  {
    name: "قرارداد ها",
    icon: "mdi-book-plus-multiple",
    url: "/contracts",
    permissions: ["own list contract", "office list contract", "full list contract"],
    slug: "contracts"
  },
  {
    url: "/events",
    name: "رویداد ها",
    permissions: ["office list event", "full list event", "own list event"],
    slug: "events",
    icon: "mdi-calendar-check"
  },
  {
    name: "کاربران",
    icon: "mdi-account",
    permissions: [
      "add user",
      "full list user",
      "office list user",
      "own list user"
    ],
    submenu: [
      {
        url: "/users-add",
        name: "کلاینت جدید",
        permissions: ["add user"],
        slug: "add-users",
        icon: "mdi-account-plus"
      },
      {
        url: "/users",
        name: "کلاینت‌ها",
        permissions: ["full list user", "office list user", "own list user"],
        slug: "users",
        icon: "mdi-account-group"
      },
      {
        url: "/advisers",
        name: "مشاور - ادمین",
        permissions: ["full list user"],
        slug: "advisers",
        icon: "mdi-comment-account"
      }
    ]
  },

  {
    name: "گزارشات",
    icon: "mdi-chart-areaspline",
    permissions: ["full report", "office report", "own report"],
    submenu: [
      {
        url: "/report/general",
        name: "گزارشات کلی",
        permissions: ["full report"],
        slug: "report"
      },
      {
        url: "/report/bests",
        name: "بهترین ها",
        permissions: ["full report"],
        slug: "report-bests"
      },
      {
        url: "/report/week",
        name: "گزارشات هفتگی",
        permissions: ["full report", "office report"],
        slug: "report-week"
      },
      {
        url: "/report/call",
        name: "گزارشات تماس ها",
        permissions: ["full report"],
        slug: "report-call"
      }
    ]
  },
  {
    name: "تنظیمات",
    icon: "mdi-cogs",
    permissions: [
      "office",
      "access control",
      "setting",
      "notification manager",
      "coupon"
    ],
    submenu: [
      {
        url: "/coupon/list",
        name: "کوپن ها",
        icon: "mdi-ticket-percent",
        permissions: ["coupon"],
        slug: "coupon-list"
      },
      {
        url: "/offices",
        name: "دفاتر",
        permissions: ["office"],
        slug: "offices",
        icon: "mdi-office-building"
      },
      {
        url: "/access",
        name: "دسترسی ها",
        permissions: ["access control"],
        slug: "access",
        icon: "mdi-shield"
      },
      {
        url: "/notifications",
        name: "مدیریت نوتیفیکیشن ها",
        permissions: ["notification manager"],
        slug: "access",
        icon: "mdi-bell-ring"
      }
    ]
  },
  {
    name: "پروفایل",
    icon: "mdi-account-box-outline",
    permissions: [
      "profile",
    ],
    submenu: [
      {
        url: "/profile",
        name: "پروفایل",
        permissions: ["profile"],
        slug: "profile",
      },
      {
        url: "/profile-file",
        name: "پرونده",
        permissions: ["profile"],
        slug: "file",
        icon: 'mdi-file',
      },
      {
        url: "/profile-file-detail",
        name: "جزئیات پرونده",
        permissions: ["profile"],
        slug: "file-detail",
        icon: 'mdi-file-document',
      }
    ]
  },
];
